import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        fontSize: "14px",
        textAlign: "center",
        padding: 14,
        width: "100%",
        color : "#727272",
      }}
    >
      This is not the website of official EMAAR and also not related to the
      official EMAAR.
    </div>
  );
};

export default Footer;
